import React from "react";
//import { useStaticQuery, graphql } from "gatsby";
import SignUp from "./signup";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="column is-4 is-offset-4">
        <SignUp />
      </div>
      <div className="content has-text-centered">
        <p>
          <a href="mailto:hello@toastavocado.com">hello@toastavocado.com</a>
        </p>
        <p>© {new Date().getFullYear()}, Toast Avocado</p>
      </div>
    </footer>
  );
};

export default Footer;
