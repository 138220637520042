import React, { useState } from "react";
import addToMailchimp from "gatsby-plugin-mailchimp";

const createMessageElt = (message) => {
  if (!message) {
    return <span />;
  } else if (
    message.result === "error" &&
    message.msg.includes("already subscribed")
  ) {
    return <div className="notification">Email is already subscribed</div>;
  } else if (message.result === "error") {
    return <div className="notification">Sorry, there was an error</div>;
  } else if (message.result === "success") {
    return <div className="notification">Thanks for subscribing!</div>;
  } else {
    return <span />;
  }
};

const SignUp = () => {
  const [message, setMessage] = useState();
  const handleSubmit = async (event) => {
    event.preventDefault();
    const { target } = event;
    const result = await addToMailchimp(target.email.value);
    setMessage(result);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="field has-addons">
          <div className="control is-expanded">
            <input
              className="input"
              type="email"
              name="email"
              placeholder="Enter your email"
            />
          </div>
          <div className="control">
            <input
              type="submit"
              className="button is-primary"
              value="Subscribe"
            />
          </div>
        </div>
      </form>
      <div>{createMessageElt(message)}</div>
    </div>
  );
};

export default SignUp;
